require('bootstrap/dist/css/bootstrap.min.css');
require('react-big-calendar/lib/css/react-big-calendar.css');
require('slick-carousel/slick/slick.css');
require('slick-carousel/slick/slick-theme.css');
require('whatwg-fetch');

const { registerLinkResolver } = require('gatsby-source-prismic-graphql');
const { linkResolver } = require('./src/utils/linkResolver');

registerLinkResolver(linkResolver);

// Scroll back to top of page when navigating to new page.
// @see https://github.com/gatsbyjs/gatsby/issues/7454
// @see https://github.com/ryanwiemer/rw/issues/1#issuecomment-361003862
exports.onRouteUpdate = args => {
  if (typeof window !== 'undefined' && args.location.action === 'PUSH') {
    window.scrollTo(0, 0);
  }
};

exports.shouldUpdateScroll = args => {
  // Scroll on < back, forward >, refresh, but not link clicks
  return !!(
    args.prevRouterProps == null ||
    (args.prevRouterProps && args.prevRouterProps.location.action === 'POP')
  );
};
