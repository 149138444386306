module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"typekit":{"id":"bvz8jyw"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"The Small Exchange","short_name":"starter","start_url":"/","background_color":"#663399","theme_color":"#663399","display":"minimal-ui","icon":"src/images/smallx_fav_icon.png","legacy":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://thesmallexchange.com"},
    },{
      plugin: require('../node_modules/gatsby-source-prismic-graphql/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"smalls-website-staging-repository","accessToken":"MC5YZkotWmhFQUFDSUEtTGVI.77-977-9VELvv70W77-9Cu-_vSfvv73vv71tGjJ1fgrvv73vv73vv71PEhIkJ--_vTlxACE7","previews":false,"pages":[{"type":"Standard_page","match":"/:uid","path":"/","component":"/var/www/small-exchange/small-exchange-prismic/src/templates/page.js"},{"type":"Products_landing_page","match":"/:uid","path":"/products","component":"/var/www/small-exchange/small-exchange-prismic/src/templates/productsLandingPage.js"},{"type":"Notifications_page","match":"/:uid","path":"/notifications","component":"/var/www/small-exchange/small-exchange-prismic/src/templates/notificationsPage.js"},{"type":"Product","match":"/products/:uid","path":"/products/:uid","component":"/var/www/small-exchange/small-exchange-prismic/src/templates/product.js"},{"type":"Contracts_page","match":"/:uid","path":"/contracts","component":"/var/www/small-exchange/small-exchange-prismic/src/templates/contractsPage.js"}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-132141076-1"},
    },{
      plugin: require('../node_modules/gatsby-plugin-facebook-pixel/gatsby-browser.js'),
      options: {"plugins":[],"pixelId":"418364161628854"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
